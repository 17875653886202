'use client'
import { usePathname } from 'next/navigation'
import { useEffect } from 'react'

export default function ScrollRestoration() {
  const pathname = usePathname()

  useEffect(() => {
    // timeout
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 50)
  }, [pathname])

  return <div></div>
}
