import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"../../packages/app/(application)/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/packages/components/landing/MerchantLogo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/packages/components/navigation/ApplicationNavbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/packages/components/navigation/ScrollRestoration.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/vercel/path0/packages/components/providers/providers.tsx");
