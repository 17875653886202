'use client'
import MenuLinkItem from '@jobmojito/components/navigation/MenuLinks/MenuLinkItem'
import React from 'react'
import { usePathname } from 'next/navigation'
import IsPath from '@jobmojito/app/utils/data/platformLanguagesIsPathClient'
import { useState } from 'react'

export default function MenuLinks({
  onClick,
  menuItems,
}: {
  onClick: () => void
  menuItems: {
    href: string
    label: string
    icon?: React.ReactNode
    iconSel?: React.ReactNode
    enabled: boolean | undefined
  }[]
}) {
  // receive merchant data from cookies, written by middleware
  const pathname = usePathname()

  return (
    <>
      {menuItems.map((link) => {
        const isActive = IsPath(pathname, link.href)
        const splitLabel = link.label.split('|')
        if ((link.enabled!==undefined) && (link.enabled))
          return (
            <MenuLinkItem
              className={
                'hover:underline ' +
                (isActive &&
                  'border-b-2 border-black font-bold xl:h-full content-center xl:px-4 py-1 xl:bg-background bg-opacity-100 text-black')
              }
              isActive={isActive}
              icon={isActive ? link.iconSel : link.icon}
              key={link.href}
              href={link.href}
              onClick={onClick}
            >
              {splitLabel.length === 1 ? (
                link.label
              ) : (
                <>
                  {splitLabel[0]}
                  <div className="text-xs font-light ">{splitLabel[1]}</div>
                </>
              )}
            </MenuLinkItem>
          )
      })}
    </>
  )
}
