'use client'
import { ProfileLimitedType } from '@jobmojito/config/types/collection'
import { Avatar } from '@nextui-org/avatar'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from '@nextui-org/dropdown'
import getClientSupabase from '@jobmojito/app/utils/getClientSupabase'
import NextImage from 'next/image'
import { useRouter } from 'next/navigation'
import chevronDown from './MenuLinks/assets/chevron_down.svg'
import ConfirmationModal from '@jobmojito/components/core/ConfirmationModal'
import { useState } from 'react'
import {useTranslations} from 'next-intl';
import Link from 'next/link'

export default function ProfileCard({
  profile,
  purchase_credits_enabled,
  menuLinkOnClick = () => {},
}: {
  profile: ProfileLimitedType | null | undefined
  purchase_credits_enabled: boolean
  menuLinkOnClick?: () => void
}) {
  const router = useRouter()
  const [confirmationModalShow, setConfirmationModalShow] =
    useState<boolean>(false)

  const t = useTranslations('component-nav-bar');

  function signOut() {
    const supabase = getClientSupabase()
    supabase.auth.signOut().then(() => {
      menuLinkOnClick()
      router.push('/login')
      router.refresh()
    })
  }

  function goToProfile() {
    menuLinkOnClick()
    router.push('/profile')
  }

  function goToBilling() {
    menuLinkOnClick()
    router.push('/billing')
  }

  function goToSupport() {
    menuLinkOnClick()
    router.push('/support')
  }

  return (
    <>
      <Dropdown placement="bottom-end">
        <DropdownTrigger>
          <div className={'flex flex-row cursor-pointer'}>
            <Avatar
              className={'inline-block shrink-0'}
              // @ts-ignore
              src={profile?.avatar_url}
            />

            <div className={'ml-3 whitespace-nowrap'}>
              <div className={'font-bold text-sm'}>{profile?.name}</div>
              <div className={'text-xs font-light'}>{profile?.email}</div>
            </div>
            <NextImage
              src={chevronDown}
              alt={'dropdown'}
              className={'h-7 w-7 ms-1 mt-1 self-start'}
            />
          </div>
        </DropdownTrigger>
        <DropdownMenu aria-label="Profile Actions" variant="flat">
          <DropdownItem onPress={goToProfile} key="profile">
            {t('Menu profile my profile')}
          </DropdownItem>
          <DropdownItem onPress={goToBilling} key="billing">
            {(purchase_credits_enabled?t('Menu profile billing'):t('Available credits'))}
          </DropdownItem>
          <DropdownItem onPress={goToSupport} key="support">
            {t('Menu profile support')}
          </DropdownItem>
          <DropdownItem
            onPress={() => setConfirmationModalShow(true)}
            key="logout"
            color="danger"
          >
            {t('Menu profile log out')}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>

      <ConfirmationModal
        isOpen={confirmationModalShow}
        event={signOut}
        confirmRedButton={false}
        onClose={() => setConfirmationModalShow(false)}
        confirmTextButton={t('Yes')}
        refusTextButton={t('No')}
        titleText={t('Confirm')}
      >
        {t('Do you want to log out')}
      </ConfirmationModal>
    </>
  )
}
