'use client'
import { NavbarItem } from '@nextui-org/navbar'
import React from 'react'
import Link from 'next/link'
import NextImage from 'next/image'
import { Button } from '@nextui-org/button'
import { useRouter } from 'next/navigation'

export default function MenuLinkItem({
  href,
  children,
  className = '',
  isActive = false,
  icon,
  onClick = () => {},
}: {
  href: string
  children: React.ReactNode
  className?: string
  isActive?: boolean
  icon: React.ReactNode,
  onClick?: () => void
}) {
  const router = useRouter()
  function handleLink(event: any, href: string) {
    event.preventDefault()
    onClick()
    router.push(href)
  }

  const DivClassName = isActive
    ? 'text-black font-bold'
    : 'font-medium';

  return (
    <Link
      className={'text-foreground text-lg ' + className}
      href={href}
      onClick={() => handleLink(event, href)}
    >
      <NavbarItem className={'items-top flex gap-2'}>
        {icon}
        <div className={DivClassName}>{children}</div>
      </NavbarItem>
    </Link>
  )
}
