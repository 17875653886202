'use client'
import { Popover, PopoverTrigger, PopoverContent, Button } from '@nextui-org/react'
import { language, languagesRaw, languagesSorted ,findLanguage } from '@jobmojito/app/utils/data/platform-languages'
import {useParams, useSearchParams } from 'next/navigation';
import {ChangeEvent, ReactNode, useTransition, useEffect} from 'react';
import { Link, useRouter, usePathname } from './Navigation'
import {useLocale, useTranslations} from 'next-intl';

export default function LanguageSelector({
  languages
}: {
  languages: language[]
}) {
    const router = useRouter();
    const pathname = usePathname();
    const searchParams = useSearchParams();
    const locale = useLocale();

    const reloadPage = searchParams.get('reload')

    useEffect(() => {
      if ((reloadPage!==undefined) && (reloadPage!==null)) { 
        if (typeof window !== "undefined") window.location.href = pathname;
      }
    }, [searchParams, pathname]);
  
    function changeLanguage (newLanguage: string){
      //
      router.push((pathname+'?reload=true') as never, {locale: newLanguage})
        /*
        router.replace(
            // @ts-expect-error -- TypeScript will validate that only known `params`
            // are used in combination with a given `pathname`. Since the two will
            // always match for the current route, we can skip runtime checks.
            {pathname, params},
            {locale: newLanguage}
          );
        */
    }

  return (
    (languages===undefined || languages.length<2) ?
      <></>
    :
      <Popover showArrow placement="bottom" backdrop='opaque'>
          <PopoverTrigger>
              <div className='text-black text-sm underline underline-offset-4 font-bold cursor-pointer' role='button'>{findLanguage(locale)?.flag_emoji} { findLanguage(locale)?.name_local }</div>
          </PopoverTrigger>
          <PopoverContent className="p-4">
              <ul className='grid grid-cols-2 lg:grid-cols-3 text-medium text-black'>
                  {languages.map((l)=> {
                      return <li key={'lang-'+l.code}>
                              <Button
                              onClick={(()=> { changeLanguage(l.i18_code) })}
                              color="primary"
                              variant="light"
                              size='md'
                              className='w-full'
                              >
                              {l.flag_emoji} {l.name_local}
                              </Button>
                      </li>
                  })}
              </ul>
          </PopoverContent>
      </Popover>
  )
}
