'use client'
import getClientSupabase from '@jobmojito/app/utils/getClientSupabase'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Button
} from '@nextui-org/react'
import { useRouter, useSearchParams } from 'next/navigation'
import { useState, useEffect } from 'react'
import {Spinner} from "@nextui-org/react";
import clientGetCookies from '@jobmojito/components/core/clientCookies'
import NextImage from 'next/image'
import chevronDown from './MenuLinks/assets/chevron_down.svg'
import { ProfileLimitedType } from '@jobmojito/config/types/collection'

type MerchantType = {
  name: string|null
  id: string
  status: string
}

export const dynamic = 'force-dynamic'
export default function UserDropdown({
  profile,
  profileId,
  originalProfileId
}: {
  profile: ProfileLimitedType
  profileId: string
  originalProfileId: string | undefined
}) {

  const supabase = getClientSupabase()
  const router = useRouter()
  const searchParams = useSearchParams()
  const search = searchParams.get('reload')

  const [loaded, setLoaded] = useState<boolean>(false);
  const [dropdownSelect, setDropdownSelect] = useState<React.ReactNode|undefined>()
  const [listProfiles, setListProfiles] = useState<MerchantType[]|undefined>()
  const [selectedProfile, setSelectedProfile] = useState<MerchantType|undefined>()

  useEffect(() => {
    if ((search!==undefined) && (search!==null)) { 
      if (typeof window !== "undefined") window.location.href = '/home/';
    }

    if (profile.type === 'mentor') {
      supabase
        .from('profile')
        .select('name, status, id')
        .order('name')
        .then(({ data: merchants, error: merchantsError })=> {
          if ((merchants!==undefined) && (merchants!==null) && (!loaded)) {
            setLoaded(true);
            setListProfiles(merchants);
            setSelectedProfile(merchants.find((m)=> (m.id === (clientGetCookies('set_profile_id') || profileId || ''))))
          }
        })
    }
    /*
    if ((profile.type !== 'mentor')  && (clientGetCookies('set_profile_id'))) {
      router.push('/?set_profile_id=delete');
    }*/
  }, [search]);

  if ((profile.type === 'mentor')) {
    // hard reload to get cookies up and running
      return (
        <>
          {(loaded && listProfiles)?
            <Popover showArrow placement="bottom" backdrop='opaque'>
            <PopoverTrigger>
              <div className={'flex flex-row cursor-pointer'}>
                <div className='flex flex-col'>
                  <div className="text-xs">View Mentee:</div>
                  <div className='text-black text-sm underline-offset-4 font-bold cursor-pointer text-nowrap' role='button'>{selectedProfile ? selectedProfile.name : 'Not Selected'}</div>
                </div>
                <NextImage
                  src={chevronDown}
                  alt={'dropdown'}
                  className={'h-7 w-7 ms-1 mt-1 self-start'}
                />
              </div>
            </PopoverTrigger>
            <PopoverContent className="p-4">
                <ul className='grid grid-cols-2 lg:grid-cols-3 text-medium text-black'>
                    {listProfiles.map((m)=> {
                        return <li key={'lang-'+m.id}>
                                <Button
                                onClick={(()=> { router.push('/home/?set_profile_id='+m.id)})}
                                variant="light"
                                size='md'
                                className={'w-full '+(m.status==='active'?((originalProfileId===m.id)?'font-bold':'text-black'):'text-stone-400')}
                                >
                                {m.name}
                                </Button>
                        </li>
                    })}
                </ul>
            </PopoverContent>
          </Popover>:<div><Spinner size="sm" className="mr-4" /></ div>}
        </>
      )
  } 
  return <></>
}
