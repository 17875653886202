'use client'
import MenuLinks from '@jobmojito/components/navigation/MenuLinks/MenuLinks'
import ProfileCard from '@jobmojito/components/navigation/ProfileCard'
import { ProfileLimitedType } from '@jobmojito/config/types/collection'
import {
  NavbarContent,
  NavbarMenuToggle,
  Navbar as NextUiNavbar,
} from '@nextui-org/navbar'
import { NavbarMenu } from '@nextui-org/navbar'
import { useState } from 'react'
import { languagesRaw, languagesSorted ,findLanguage } from '@jobmojito/app/utils/data/platform-languages'
import LanguageSelector from '@jobmojito/components/core/LanguageSelector'
import {useTranslations} from 'next-intl';

import { IdentificationIcon, DocumentTextIcon, HomeIcon, ChatBubbleLeftRightIcon, TrophyIcon } from '@heroicons/react/24/solid'
import { IdentificationIcon as IdentificationIconOutline, DocumentTextIcon as DocumentTextIconOutline, HomeIcon as HomeIconOutline, ChatBubbleLeftRightIcon as ChatBubbleLeftRightIconOutline, TrophyIcon as TrophyIconOutline } from '@heroicons/react/24/outline'
import UserDropdown from './UserDropdown'

export default function ApplicationNavbar({
  profile,
  profileId,
  originalProfileId,
  merchantLogo,
  enabledFeatures,
  languages,
}: {
  profile: ProfileLimitedType | null | undefined
  profileId: string | undefined
  originalProfileId: string | undefined
  merchantLogo: React.JSX.Element
  enabledFeatures: {
    cover_letter_enabled: boolean
    resume_check_enabled: boolean
    interview_coach_enabled: boolean
    homepage_pricing_enabled: boolean
    purchase_credits_enabled: boolean
    trivia_enabled: boolean
  },
  languages: string[]
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const closeMenu = () => {
    setIsMenuOpen(false)
  }
  const t = useTranslations('component-nav-bar');

  // enable or disable menu items based on feature flags
  const menuItems = [
    { 
      href: '/home', 
      label: t('Menu Home'),
      enabled: true,
      icon: <HomeIconOutline className='w-5 h-5' />,
      iconSel: <HomeIcon className='w-5 h-5 text-black' />
    },
    {
      href: '/interview',
      label: t('Menu Coaching'),
      enabled: enabledFeatures.interview_coach_enabled,
      icon: <ChatBubbleLeftRightIconOutline className='w-5 h-5 mt-1' />,
      iconSel: <ChatBubbleLeftRightIcon className='w-5 h-5 text-black mt-1' />
    },
    {
      href: '/trivia',
      label: t('Menu Trivia'),
      enabled: enabledFeatures.trivia_enabled, // TODO use enabledFeatures ?,
      icon: <TrophyIcon className='w-5 h-5 mt-1' />,
      iconSel: <TrophyIconOutline className='w-5 h-5 mt-1' />
    },
    {
      href: '/resume',
      label: t('Menu Resume'),
      enabled: enabledFeatures.resume_check_enabled,
      icon: <IdentificationIconOutline className='w-5 h-5 mt-1' />,
      iconSel: <IdentificationIcon className='w-5 h-5 text-black mt-1' />
    },
    {
      href: '/cover_letter',
      label: t('Menu Cover Letter'),
      enabled: enabledFeatures.cover_letter_enabled,
      icon: <DocumentTextIconOutline className='w-5 h-5 mt-1' />,
      iconSel: <DocumentTextIcon className='w-5 h-5 text-black mt-1' />
    },
  ].filter((item) => item.enabled !== false)

  return (
    <NextUiNavbar
      maxWidth={'2xl'}
      className={'text-base md:px-10 bg-white'}
      classNames={{ wrapper: 'px-2' }}
      isBordered
      onMenuOpenChange={setIsMenuOpen}
      isMenuOpen={isMenuOpen}
    >
      <div className={' h-full pt-2 pb-2'}>
        <div className="relative w-full h-full">{merchantLogo}</div>
      </div>

      <NavbarContent
        className="hidden xl:flex flex-row grow gap-x-8 gap-y-8 "
        justify={'center'}
      >
        <MenuLinks onClick={closeMenu} menuItems={menuItems} />
      </NavbarContent>

      {/*<NavbarContent className="flex flex-row grow-0" justify={'end'}>
        <NotificationsButton /> 
      </NavbarContent>*/}

      <div className={'flex flex-row grow xl:grow-0 place-content-end gap-5'}>
        <LanguageSelector
            languages={languagesSorted.filter((l)=>(languages.includes(l.code)))}
        />
      </div>
      <div className={'hidden xl:flex flex-row grow-0 items-center gap-5'}>
        {(profile && profileId && profile.type==='mentor') && <UserDropdown profile={profile} profileId={profileId} originalProfileId={originalProfileId} /> }
        <ProfileCard profile={profile} purchase_credits_enabled={enabledFeatures.purchase_credits_enabled} />
      </div>
      <NavbarMenuToggle
        aria-label={isMenuOpen ? t('Close menu') : t('Open menu')}
        className="xl:hidden grow-0"
      />
      <NavbarMenu className='flex flex-col gap-5'>
        <MenuLinks onClick={closeMenu} menuItems={menuItems} />
        <div className='flex flex-col gap-8'>
          {(profile && profileId && profile.type==='mentor') && <UserDropdown profile={profile} profileId={profileId} originalProfileId={originalProfileId} /> }
          <ProfileCard profile={profile} menuLinkOnClick={closeMenu} purchase_credits_enabled={enabledFeatures.purchase_credits_enabled} />
        </div>
      </NavbarMenu>
    </NextUiNavbar>
  )
}
