'use client'
import clientGetCookies from '@jobmojito/components/core/clientCookies'

export default function isPath(currentPath:string, path: string): boolean {
    const pathUpdated = (path.charAt(0)==='/'?path:'/'+path);
    const locate = clientGetCookies('NEXT_LOCALE'); 

    if (pathUpdated==='/') {
        if ((currentPath === '/') || (currentPath==='/'+locate)) return true;
    } else {
        if (currentPath.startsWith(pathUpdated) || currentPath.startsWith('/'+locate+pathUpdated)) {
            return true;
        }
    }

    return false;
}